/* Tabel Header */
.bo-table.ant-table-wrapper th.ant-table-cell {
  background: transparent;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  &:before {
    background-color: #e0e0e0;
  }
}

.bo-table.ant-table-wrapper .ant-table-filter-trigger {
  background: transparent;
}

/* Table row */
.bo-table.ant-table-wrapper td.ant-table-cell {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 16px;
}

/* Table Pagination */
.bo-table.ant-table-wrapper .ant-pagination-simple .ant-pagination-simple-pager input:focus {
  box-shadow: none;
  border-color: black
}
